export const defaultVariables = [
  {
    title: "Contact",
    options: [
      {
        label: "First Name",
        value: "{{contact.firstname}}",
      },
      {
        label: "Last Name",
        value: "{{contact.lastname}}",
      },
      { label: "Phone", value: "{{contact.phone}}" },
      { label: "Email", value: "{{contact.email}}" },
    ],
  },
  {
    title: "General",
    options: [
      { label: "Current Time", value: "{{current_time}}" },
      { label: "Current Date", value: "{{current_date}}" },
      { label: "Current Day Of week", value: "{{current_day_of_week}}" },
      { label: "Resource Recipient Number", value: "{{resource_recipient_number}}" },
      { label: "Chat Log", value: "{{chat_log}}" },
      { label: "Dialog Metadata", value: "{{dialog_metadata}}" },
      { label: "Agent Name", value: "{{agent_name}}" },
      { label: "Call Direction", value: "{{call_direction}}" },
      { label: "User Prompt", value: "{{user_prompt}}" },
      { label: "Call Direction Message", value: "{{call_direction_message}}" },
      { label: "Screening Questions", value: "{{screening_questions}}" },
      { label: "Subindustries", value: "{{subindustries}}" },
      { label: "Industry", value: "{{industry}}" },
      { label: "Account Name", value: "{{account_name}}" },
      { label: "Personality", value: "{{personality}}" },
      { label: "Tools Prompt", value: "{{tools_prompt}}" },
    ]
  }
];
