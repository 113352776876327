import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { CallTranscription, MenuRight } from "../../../components";
import { myExtensionPetition } from "../../../services/myExtension.service";
import { downloadAudioService, downloadCallService, fetchNumber, fetchQueues, fetchUsers } from "./services";
import { processViewLogs } from "./utils";
import { useDynamicFunction } from "../../../contexts/DynamicFunctionContext";
import DataReport from "./components/DataReport/DataReport";
import ModalError from "./components/ModalUtility/ModalError";
import CardInfo from "./components/CardInfo/CardInfo";
import SelectReport from "./components/SelectReport/SelectReport";
import "./Analytics.css";

const Analytics = () => {
  const navigate = useNavigate();
  const { setDynamicFunction, resetFunction, setIsArrowVisible } = useDynamicFunction();

  const [myExtension, setMyExtension] = useState({})
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [optionsUserReport, setOptionsUserReport] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [optionsNumberReport, setOptionsNumberReport] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [optionsQueuesReport, setOptionsQueuesReport] = useState([])
  const [selectedQueuesReport, setSelectedQueuesReport] = useState([])
  const [optionsQueueMembersReport, setOptionsQueueMembersReport] = useState([])
  const [selectedQueueMembersReport, setSelectedQueueMembersReport] = useState([])
  const [dataReport, setDataReport] = useState(false); 
  const [viewLogs, setViewLogs] = useState(null);
  const [loadingCall, setLoadingCall] = useState(null)
  const [modalError, setModalError] = useState(false)
  const [completedLogs, setCompletedLogs] = useState({})
  const [callEvents, setCallEvents] = useState(null)
  const [showCallTranscription, setShowCallTranscription] = useState(null)
  const [timeframe, setTimeframe] = useState({
    timeStart: "",
    timeEnd: "",
  });

  const closeModal = () => {
    setModalError(false)
  }

  const downloadCall = async (value) => {
    await downloadCallService(value, setLoadingCall, setModalError)
  };

  const activeCallTranscription = (e) => {
    if (e?.callTranscription) {
      setShowCallTranscription(e)
      setOpenMenu(true)
    }
  }

  const activeViewLogs = (element) => {
    const isNumberReport = selectedReport === "Numbers" ? true : false
    const logs = processViewLogs(element, myExtension, isNumberReport)
    setCompletedLogs(logs)
    setViewLogs({
      columns: [
        { name: "Date & Time", key: "datetime" },
        { name: "From", key: "fromNumber" },
        { name: "To", key: "toNumber" },
        { name: "Billable Time", key: "billsec" },
        { name: "Ring Time", key: "duration" },
        { name: "Disposition", key: "disposition" },
      ],
      content: [...logs.all_calls],
      actions: {
        title: "Actions",
        content: [
          {
            name: "Download recording",
            icon: <FontAwesomeIcon icon={faDownload} />,
            handleClick: downloadCall,
            canBeDisabled: true,
            keyDisabled: "call_recording_filename",
            hoverDisabledAction: true,
            textHover: "This call was not recorded or no longer available",
          },
          {
            name: "View transcription",
            icon: <FontAwesomeIcon icon={faEye} />,
            handleClick: activeCallTranscription,
            canBeDisabled: true,
            keyDisabled: "callTranscription",
            hoverDisabledAction: true,
            textHover: "This transcription is not available",
          },
        ],
      },
    });
  };

  const activeDataReport = () => {
    setOpenMenu(false);
    setDataReport(true);
  };

  const onChangeDate = (e) => {
    const { name, value } = e.target
    setTimeframe({ ...timeframe, [name]: value });
  };

  const onChangeUsersReport = (e) => {
    setSelectedUsers(e);
  };

  const onChangeNumbersReport = (e) => {
    setSelectedNumbers(e);
  };

  const onChangeQueueReport = (e) => {
    setSelectedQueuesReport(e)
  }

  const onChangeQueueMemberReport = (e) => {
    setSelectedQueueMembersReport(e)
  }

  const handleChangeSelectReport = (e) => {
    setSelectedReport(e.target.value);
  };

  const closeMenu = () => {
    setSelectedNumbers(optionsNumberReport);
    setSelectedUsers(optionsUserReport);
    setSelectedReport("");
    setShowCallTranscription(null)
    setOpenMenu(false);
  };

  const activeMenuReport = () => {
    setOpenMenu(true);
  };

  const selectOnChangeSelect = (e) => {
    switch (selectedReport) {
      case "Users":
        onChangeUsersReport(e)
        break;
      case "Numbers":
        onChangeNumbersReport(e)
        break;
      case "Queues":
        onChangeQueueReport(e)
        break;
      case "Queue Members":
        onChangeQueueMemberReport(e)
        break;
      default:
        break;
    }
  }

  const selectValueinSelectedReport = () => {
    switch (selectedReport) {
      case "Users":
        return selectedUsers
      case "Numbers":
        return selectedNumbers
      case "Queues":
        return selectedQueuesReport
      case "Queue Members":
        return selectedQueueMembersReport
      default:
        break;
    }
  }

  const selectOptionInSelectedReport = () => {
    switch (selectedReport) {
      case "Users":
        return optionsUserReport
      case "Numbers":
        return optionsNumberReport
      case "Queues":
        return optionsQueuesReport
      case "Queue Members":
        return optionsQueueMembersReport
      default:
        break;
    }
  }

  const petition = async () => {
    setLoading(true);

    try {
      await fetchQueues(setOptionsQueuesReport, setSelectedQueuesReport)
      
      await fetchNumber(setTimeframe, setOptionsNumberReport, setSelectedNumbers)

      await fetchUsers(setSelectedUsers, setOptionsUserReport, setOptionsQueueMembersReport, setSelectedQueueMembersReport)

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (dataReport) {
      setDynamicFunction(() => () => {
        if (!viewLogs && !callEvents) {
          setDataReport(false);
        } else if (viewLogs && !callEvents) {
          setViewLogs(null);
          setCompletedLogs({})
        } else if (viewLogs && callEvents) {
          setCallEvents(null)
        }
      });
      setIsArrowVisible(true);
    } else {
      setIsArrowVisible(false);
      resetFunction();
    }
  }, [dataReport, viewLogs, callEvents, callEvents])

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()
        const granularPermission = data?.admin_analytics
        if (data?.priv_level && data?.priv_level !== "admin" && !granularPermission) {
          navigate("/Dashboard");
        } else {
          setMyExtension(data)
          petition();
        }

      } catch(error) {
        console.log(error)
      }
    }

    myExtensionData()
  }, []);

  return (
    <div className="itp-test">
      {modalError && (
        <ModalError 
          modal={modalError}
          closeModal={closeModal}
        />
      )}

      {dataReport ? (
        <>
          <DataReport
            selectedReport={selectedReport}
            selectedUsers={selectedUsers}
            selectedNumbers={selectedNumbers}
            selectedQueuesReport={selectedQueuesReport}
            selectedQueueMembersReport={selectedQueueMembersReport}
            timeframe={timeframe}
            viewLogs={viewLogs}
            setViewLogs={setViewLogs}
            activeViewLogs={activeViewLogs}
            loadingCall={loadingCall}
            timeZone={myExtension.time_zone}
            completedLogs={completedLogs}
            callEvents={callEvents}
            setCallEvents={setCallEvents}
            totalUsers={optionsUserReport}
            totalQueues={optionsQueuesReport}
            myExtension={myExtension}
            setModalError={setModalError}
            setLoadingCall={setLoadingCall}
          />
        </>
      ) : (
        <CardInfo activeMenuReport={activeMenuReport} />
      )}

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Run a Report"
        subtitle="Fill the details below related to the needed report"
        to=""
      >
        {openMenu && !showCallTranscription && (
          <>
            <SelectReport 
              timeframe={timeframe}
              onChangeDate={onChangeDate}
              handleChangeSelectReport={handleChangeSelectReport}
              selectedReport={selectedReport}
              selectOnChangeSelect={selectOnChangeSelect}
              selectValueinSelectedReport={selectValueinSelectedReport}
              selectOptionInSelectedReport={selectOptionInSelectedReport}
              selectedUsers={selectedUsers}
              selectedNumbers={selectedNumbers}
              selectedQueuesReport={selectedQueuesReport}
              selectedQueueMembersReport={selectedQueueMembersReport}
              activeDataReport={activeDataReport}
              onChangeQueueReport={onChangeQueueReport}
              optionsQueuesReport={optionsQueuesReport}
              loading={loading}
            />
          </>
        )}

        {openMenu && showCallTranscription && (
          <>
            <CallTranscription 
              customDownload={() => downloadAudioService(showCallTranscription)}
              transcriptions={showCallTranscription?.call_transcription?.formatedTranscription || []}
              callSummaryData={showCallTranscription?.call_transcription || {}}
            />
          </>
        )}
      </MenuRight>
    </div>
  );
};

export default Analytics;
